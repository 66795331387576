#portfolio {
    margin-bottom: 30px;
    text-align: center;
  }
  
  #portfolio #projectContainer {
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  
  @media screen and (max-width: 800px) {
    #portfolio .flex {
        flex-direction: column;
    }
  }