@font-face {
    font-family: "Bowlby One SC";
    src: url('../../resources/BowlbyOneSC-Regular.ttf');
}

#banner {
    position: relative;
    
    background-image: url('/public/images/BannerImg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-attachment: fixed;
    z-index: -2;
  
    height: 100vh;
  
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    text-align: center;
  
    color: #aec3d6;
  
    text-shadow: 10px 10px 10px black;
    font-size: 2.25rem;
}

#banner::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha channel (last value) for darkness */
}

#banner img {
    width: 50%;
    z-index: 2;
}

#banner h1,
#banner h4,
#banner hr {
    z-index: 2;
    margin: 0;
}

hr {
    border-top: 6px solid #aec3d6;
    border-radius: 5px;
    width: 40rem;
    box-shadow: 10px 10px 10px black;
}

.bowlby-one-sc-regular {
    font-family: "Bowlby One SC", sans-serif;
    font-weight: 400;
    font-style: normal;
  }  


@media screen and (max-width: 800px) {
    #banner {
        font-size: 1.25rem;
    }
    hr {
        width: 80%;
    }
}