#about {
    text-align: center;
    justify-content: space-between;
    align-items: center;
    height: 20rem;
    overflow: hidden;
    box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.5);
  }
  
  #about .container {
    padding: 0 15px;
  }
  
  #about p {
    margin-top: -15px;
  }
  
  #about img {
    width: 50%;
    position: relative;
    top: 5rem;
    box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.5);
  }
  
  @media screen and (min-width: 1250px) {
    #about p {
        font-size: 1.25rem;
        margin: 0 75px;
    }
    #about img {
        width: 75%;
    }
  }

  @media screen and (max-width: 650px) {
    #about {
        background-image: url('/public/images/Me.webp');
        background-size: cover;
        position: relative;
        z-index: -2;
    }
    #about::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -2;
      }
    #about img {
        display: none;
    }
    #about h2,
    #about p {
        color: #aec3d6;
        z-index: 200;
    }
    #about h2 {
        border-bottom: 2px solid #aec3d6;
    }
  }