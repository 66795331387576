#contact {
    background-color: #25375c;
    color: #aec3d6;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
}

#contact h2 {
    border-bottom: 2px solid #aec3d6;
    margin-bottom: -10px;
}

#contact img {
    width: 75px;
    background-color: #ffffff00;
    border-radius: 1vh;
    transition: background-color 0.2s;
}

#contact img:hover {
    background-color: #0b1424;
}

@media screen and (max-width: 650px) {
    #contact {
        flex-direction: column;
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 350px) {
    #contact h3 {
        font-size: 1rem;
    }
}