#substack {
    text-align: center;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
  }
  
  #substack-feed-embed {
    text-align: left;
    width: 75%;
  }
  
  #substack-feed-embed a {
    transform: scale(1);
    transition: transform 0.2s;
  }
  
  #substack-feed-embed a:hover {
    transform: scale(1.05);
  }
  
  @media screen and (min-width: 2155px) {
    #substack-feed-embed {
        width: 60%;
    }
  }

  @media screen and (max-width: 1620px) {
    #substack-feed-embed {
        width: 90%;
    }
  }
  
  @media screen and (max-width: 650px) {
    #substack-feed-embed {
        width: 100%;
    }
  }