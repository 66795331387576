html {
  font-size: 20px;
  background-color: #0b1424;
  color: #aec3d6;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: #aec3d6;
  font-size: 1.25rem;
  text-decoration: none;
}

h2 {
  font-size: 1.75rem;
  border-bottom: 2px solid #aec3d6;
  display: inline-block;
  padding: 5px 20px;
}

button {
  font-size: 0.75rem;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #25375c;
  color: #aec3d6;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s;
}

button:hover {
  transform: scale(1.15);
  background-color: #aec3d6;
  color: #0b1424;
}

.flex {
  display: flex;
}

.bottom-border {
  padding-bottom: 10px;
  border-bottom: #25375c6f 5px dotted;
}